<template>
  <div v-if="loaded">
    <div class="flix-list-group">
      <router-link class="flix-list-group-item flix-html-a" to="/dashboard/permissions/organisations/switch.html">
        <strong>Organisation wählen / abwählen</strong>
        Hierüber können Sie eine Organisation auswählen, die ein anderer Admin für Sie freigeschaltet hat.
      </router-link>
      <router-link class="flix-list-group-item flix-html-a" to="/dashboard/permissions/organisations/new.html">
        <strong>{{ $t('message.createNew', {name: $tc('message.organisation', 1)}) }}</strong>
        Legen Sie eine neue Organisation an, und schalten Sie diese für einen anderen Account frei.
      </router-link>
      <router-link class="flix-list-group-item flix-html-a" to="/dashboard/permissions/organisations/list.html">
        <strong>{{ $t('message.edit', {name: $tc('message.organisation', 1)}) }}</strong>
        Bearbeiten Sie Organisationen und vergebene Rechte, die Sie bereits definiert haben.
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data () {
    return {
      loaded: true
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}
</script>
<style lang="sass" scoped>
  strong
    display: block
</style>
